<template>
  <section class="addin">
    <b-overlay :show="loading" rounded="sm">
      <div v-if="isSecured">
        <InformationMessage>
          <i class="fal fa-lock"></i> {{ $t("READ.SECURE_MESSAGE") }}
        </InformationMessage>

        <InformationMessage type="error" v-if="cannotOpen">
          <i class="fal fa-exclamation-circle"></i> {{ $t("READ.CANNOT_OPEN") }}
        </InformationMessage>

        <button
          v-on:click="readMessage"
          class="btn btn-fill btn-block btn-primary"
        >
          {{ $t("READ.BUTTON") }}
        </button>
      </div>

      <div v-if="isSecured == false">
        <InformationMessage type="warning">
          <i class="fal fa-exclamation-circle"></i> {{ $t("READ.NOT_SECURE") }}
        </InformationMessage>

        <button disabled class="btn btn-fill btn-block btn-primary">
          {{ $t("READ.BUTTON") }}
        </button>
      </div>
    </b-overlay>
  </section>
</template>
<script>
import outlookMethods from "@/mixins/outlookMethods";
import localMethods from "@/mixins/localMethods";
import ewsMethods from "@/mixins/ewsMethods";
import restMethods from "@/mixins/restMethods";
export default {
  mixins: [outlookMethods, localMethods, ewsMethods, restMethods],
  components: {
    InformationMessage: () => import("@/components/Layout/InformationMessage"),
  },
  data() {
    return {
      addinUrl: process.env.VUE_APP_ADDIN_URL,
      email: this.$Office.context.mailbox.userProfile.emailAddress,
      dialog: {},
      isSecured: false,
      cannotOpen: false,
      contentData: "",
      mailItemId: "",
      actionMessage: "",
      loading: false,
    };
  },
  methods: {
    logmessage(text) {
      this.$store.dispatch("logger/add", text);
    },
    async init() {
      this.$store.dispatch("logger/add", "Read init");
      this.loading = true;
      this.actionMessage = this.$t("READ.ACTION.VERIFYING_MESSAGE");
      this.checkIfMailIsSecured();
      this.mailItemId = this.$Office.context.mailbox.item.itemId;
    },
    async readMessage() {
      this.$store.dispatch("logger/add", "readMessage");
      this.$store.dispatch("logger/add", "mailItemId: " + this.mailItemId);
      let self = this;
      await this.$store.dispatch("user/fetchUser");
      this.loading = true;
      this.actionMessage = this.$t("READ.ACTION.READING_INFORMATION");
      let attachments = [];
      if (this.user.information !== null) {
        try {
          attachments = await this.outlookGetAttachments(this.mailItemId);
        } catch (error) {
          self.loading = false;
          self.cannotOpen = true;
          return "";
        }
        this.$store.dispatch(
          "logger/add",
          "attachments.length:" + attachments.length
        );
        for (let ix = 0; ix < attachments.length; ix++) {
          this.$store.dispatch("logger/add", "attachments" + ix);
          let attachment = attachments[ix];
          if (attachment.Name == "SEFOS.trv") {
            this.$store.dispatch("logger/add", "found " + this.fileCheck);
            let attachmentInfo = await this.outlookGetAttachment(
              this.mailItemId,
              attachment.Id
            );
            this.contentData = attachmentInfo.ContentBytes;
          }
        }
        if (this.contentData != "") {
          let secureMessage = null;
          try {
            let base64 = atob(this.contentData);
            secureMessage = JSON.parse(base64);
          } catch (e) {
            let base64 = atob(this.contentData);
            base64 = atob(this.base64);
            secureMessage = JSON.parse(base64);
            //secureMessage = JSON.parse(this.contentData);
          }
          if (secureMessage == null) {
            this.loading = false;
            this.cannotOpen = true;
            return false;
          }
          if (secureMessage.message_uuid == undefined) {
            this.loading = false;
            this.cannotOpen = true;
            return false;
          }
          let message_uuid = secureMessage.message_uuid;
          /*if(secureMessage.encrypted_body != "")
          {
            let found = await this.verifyExists(secureMessage.message_uuid); 
            if(found == false)
            {
              this.$store.dispatch('logger/add',"found contentData");          
              message_uuid = await this.storeMessage(this.contentData); 
              this.$store.dispatch('logger/add',"message_uuid: " + message_uuid);          
            }
          }*/
          if (message_uuid != "") {
            let dialogMessageUUid = encodeURI(message_uuid);
            console.log(this.user.information);
            this.$Office.context.ui.displayDialogAsync(
              self.addinUrl +
                "/index.html?action=readDialog&email=" +
                this.user.information.email +
                "&message_uuid=" +
                dialogMessageUUid,
              { height: 80, width: 65, displayInIframe: true },
              self.readCallback
            );
            return true;
          } else {
            this.loading = false;
            this.cannotOpen = true;
            return false;
          }
        } else {
          this.loading = false;
          this.cannotOpen = true;
          return false;
        }
      }
    },
    async storeMessage(content) {
      this.$store.dispatch("logger/add", "storeMessage");
      this.actionMessage = this.$t("READ.ACTION.STORING_MESSAGE");
      let self = this;
      this.cannotOpen = false;
      let payload = {
        message: content,
      };
      try {
        let result = await this.$http.post(
          this.user.hostname + "/message/store",
          payload
        );
        self.$emit("logtext", "storeMessage done");
        return result.data.message_uuid;
      } catch (error) {
        self.loading = false;
        self.cannotOpen = true;
        return "";
      }
    },
    async verifyExists(message_uuid) {
      this.$store.dispatch("logger/add", "verifyExists");
      this.actionMessage = this.$t("READ.ACTION.STORING_MESSAGE");
      let self = this;
      try {
        let result = await this.$http.post(
          this.user.hostname + "/message/exists",
          {
            message_uuid: message_uuid,
          }
        );
        self.$emit("logtext", "verifyExists done");
        return result.data.exists;
      } catch (error) {
        self.loading = false;
        self.cannotOpen = true;
        return "";
      }
    },
    async readCallback(asyncResult) {
      let self = this;
      if (asyncResult.status == "failed") {
        //showNotification(asyncResult.error.message);
      } else {
        this.dialog = asyncResult.value;
        this.dialog.addEventHandler(
          this.$Office.EventType.DialogMessageReceived,
          self.messageHandler
        );
        this.dialog.addEventHandler(
          this.$Office.EventType.DialogEventReceived,
          self.eventHandler
        );
      }
    },
    async messageHandler(arg) {
      let response = JSON.parse(arg.message);
      let item = this.$Office.context.mailbox.item;
      switch (response.message) {
        case "OPENED":
          this.loading = false;
          break;
        case "REPLY":
          this.dialog.close();
          item.displayReplyForm({
            htmlBody: response.content,
          });
          this.loading = false;
          break;
        case "REPLYALL":
          this.dialog.close();
          item.displayReplyForm({
            htmlBody: response.content,
          });
          this.loading = false;
          break;
        case "CLOSE":
          this.dialog.close();
          this.loading = false;
          break;
        case "CANCEL":
          this.dialog.close();
          this.loading = false;
          break;
        case "COULD_NOT_OPEN":
          this.dialog.close();
          this.loading = false;
          break;
        case "NOT_LOGGED_IN":
          this.dialog.close();
          this.loading = false;
          break;
        case "OPENBROWSERWINDOW":
          this.$Office.context.ui.openBrowserWindow(response.url);
          break;
        default:
          break;
      }
    },
    async eventHandler(arg) {
      this.loading = false;
      switch (arg.error) {
        case 12002:
          break;
        case 12003:
          break;
        case 12006:
          break;
        default:
          break;
      }
    },
    checkIfMailIsSecured() {
      let item = this.$Office.context.mailbox.item;
      let attachments = item.attachments;
      if (attachments.length > 0) {
        for (let ix = 0; ix < attachments.length; ix++) {
          let attachment = attachments[ix];
          if (attachment.name == "SEFOS.trv") {
            this.isSecured = true;
          }
        }
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
  },
  mounted() {
    console.log("Read mounted");
    this.init();
  },
};
</script>
<style></style>